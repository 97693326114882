import { ListTasksDocument, TaskInfoFragment } from 'generated/graphql';

export type TaskType = TaskInfoFragment['task']['__typename'];

export const SUPPORTED_TASK_TYPES: TaskType[] = [
  'AddressFeedbackFromDriver',
  'AddressReview',
  'CollectionCancelled',
  'CollectionOnMoreThanOneRoute',
  'CollectionsNotOnRoute',
  'IntercomItemForAttention',
  'PacksLeftInVan',
  'PackStuckInDepot',
  'ProofOfCompletionReview',
  'RouteActivitiesNeedAttention',
  'VehicleNotEnded',
  'ShipmentDateChange',
  'ShipmentNoLocationInDepot',
  'ShipmentOnHold',
  'ShipmentOnHoldDueToIncorrectAddress',
  'ShipmentOnMoreThanOneRoute',
  'ShipmentOnStartedRouteInDepot',
  'ShipmentsNotOnRoute',
  'ShipmentsOnRouteNotCollected',
  'ClaimReviewRequired',
];

export const LIST_SUPPORTED_TASKS = {
  query: ListTasksDocument,
  variables: { input: { supportedTaskTypes: SUPPORTED_TASK_TYPES } },
};
